import React, { useEffect, useRef, useState } from "react";
import style from "./textSizePicker.module.css";
import { useHover } from "../../../ui-components/floaters/use-hover";
import { Tooltip } from "../../../ui-components/floaters/tooltip";
import { useOutsideRefs } from "../../../utils/click-outside-handler";
import consts from "shared/consts";
/*
  Settings
*/

const PredefinedFontSizes = [10, 12, 18, 24, 36, 48, 64, 80, 144, 288];

/**********************************************************/

export function TextSizePicker({ value, onChange, multi }: { value: number; onChange?: any; multi?: boolean }) {
  const ref = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const hover = useHover(ref);
  const [focus, setFocus] = useState(false);

  value = Math.round(value);

  useOutsideRefs([dropdownRef, inputRef], () => {
    setFocus(false);
  });

  useEffect(() => {
    if (focus) inputRef.current.focus();
  }, [focus]);

  function updateFontSizeInModel(size: number) {
    if (size != value && onChange) onChange(size);
  }

  const onDropdownClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    if (target) {
      const sizeAsStr = target.dataset["size"];
      if (sizeAsStr) {
        const sizeAsNumber = parseInt(sizeAsStr, 10);
        if (!Number.isNaN(sizeAsNumber)) updateFontSizeInModel(sizeAsNumber);
      }
    }
    setFocus(false);
  };

  function incFontSize() {
    updateFontSizeInModel(Math.min(value + 1, consts.MAX_FONT_SIZE));
    setFocus(false);
  }
  function decFontSize() {
    updateFontSizeInModel(Math.max(value - 1, consts.MIN_FONT_SIZE));
    setFocus(false);
  }

  return (
    <>
      <div className={style.container} ref={ref} >
        {focus ? (
          <NumericInput
            key="input"
            ref={inputRef}
            min={consts.MIN_FONT_SIZE}
            max={consts.MAX_FONT_SIZE}
            initialValue={value}
            onChange={updateFontSizeInModel}
            close={() => setFocus(false)}
          />
        ) : (
          <div data-testid="FontSizeInput" key="static" className={style.inputFieldImposter} onClick={() => setFocus(true)}>
            {multi ? "-" : value}
          </div>
        )}
        <div className={style.dropdown}  ref={dropdownRef} onClick={onDropdownClick} hidden={!focus}>
          {PredefinedFontSizes.map((size) => (
            <div className={style.dropDownItem} key={size} data-size={size}>
              {size}
            </div>
          ))}
        </div>

        <div className={style.buttonContainer}>
          <button className={style.simpleButton} onClick={incFontSize}>
            <div className={style.arrowUp} />
          </button>
          <button className={style.simpleButton} onClick={decFontSize}>
            <div className={style.arrowDown} />
          </button>
        </div>
        {hover && <Tooltip label="Text size" relativeTo={ref} />}
      </div>
    </>
  );
}

type NumberInputProps = {
  initialValue: number;
  min: number;
  max: number;
  onChange: (n: number) => void;
  close?: () => void;
};

const NumericInput = React.forwardRef<HTMLInputElement, NumberInputProps>((props, ref) => {
  const { initialValue, min, max, onChange, close } = props;

  return (
    <form
      onSubmit={(e) => {
        close && close();
        e.preventDefault();
      }}
    >
      <input
        ref={ref}
        name="number"
        className={style.inputField}
        type="text"
        autoComplete="off"
        pattern="\d*"
        min={min}
        max={max}
        maxLength={max.toString().length}
        defaultValue={initialValue}
        onChange={(e) => {
          let val = parseInt(e.target.value) || min;
          val = Math.max(Math.min(val, max), min);
          onChange(val);
        }}
        onBlur={(e) => {
          let val = parseInt(e.target.value) || min;
          val = Math.max(Math.min(val, max), min);
          onChange(val);
        }}
      />
    </form>
  );
});
