import React, { useEffect, useMemo, useRef, useState } from "react";
import Konva from "konva";
import { Circle, Group, Line } from "react-konva";
import { Point } from "shared/datamodel/schemas/canvas-element";
import { dist2 } from "frontend/utils/math-utils";
import { KonvaEventObject } from "konva/types/Node";
import { CanvasArrow } from "./canvas-arrow";
import useStagePointerPosition from "frontend/hooks/use-stage-pointer-pos";
import { getAnchorPointsFromNode } from "frontend/utils/connector-utils";
import consts from "shared/consts";

const HandlePadding = 25;
const HandleInactiveRadius = 5;
const HandleInactiveColor = "#BAD5FF";
const HandleActiveRadius = 10;
const HandleActiveColor = "#1973FF";
const AnimationDuration = 0.15;

const DefaultAngles: any = {
  left: 180,
  right: 0,
  top: 270,
  buttom: 90,
};

function movePoint(point: { x: number; y: number }, rotation: number, distance: number) {
  let x = point.x + distance * Math.cos((rotation * Math.PI) / 180);
  let y = point.y + distance * Math.sin((rotation * Math.PI) / 180);
  return { x, y };
}

export function ShapeSockets({
  id,
  onCreateConnector,
  onAnchorHoverStart,
  onAnchorHoverEnd,
  elementsLayer,
  scale,
}: {
  id?: string;
  onCreateConnector: (mode: "click" | "drag", node: Konva.Node, side: string, pos: Point) => void;
  onAnchorHoverStart: (node: Konva.Node, side: string, pos: Point) => void;
  onAnchorHoverEnd: () => void;
  elementsLayer: Konva.Layer;
  scale: number;
}) {
  const node = useMemo(() => (id ? elementsLayer.findOne("#" + id) : null), [id]);
  const mousePos = useStagePointerPosition(node?.getStage(), "stage-space");
  const pad = HandlePadding / scale;

  if (!node || !node.getStage() || !node.attrs.isConnectable) {
    return null;
  }

  // don't render sockets for integration nodes that are part of a stack
  if (node.attrs.type == consts.CANVAS_ELEMENTS.INTEGRATION && node.attrs.element.containerId) {
    return null;
  }
  // Table elements have a ton of widgets, sockets are just too much
  if (node.attrs.type == consts.CANVAS_ELEMENTS.TABLE) {
    return null;
  }

  let baseAnchors = getAnchorPointsFromNode(node);
  if (!baseAnchors) {
    // it's possible a shape doesn't have anchors, but it's still connectable
    // then connectors can attach to the shape on its outline, at any point
    return null;
  }

  let anchors = Object.entries(baseAnchors).map(([anchorName, anchor]) => {
    anchor.rotation ??= DefaultAngles[anchorName] ?? 0;
    (anchor as any).drawAt = anchor.onBoundingBox ? movePoint(anchor, anchor.rotation, pad) : anchor;
    return [anchorName, anchor] as const;
  });

  let closestPairDistance = Infinity;
  for (let i = 0; i < anchors.length; i++) {
    for (let j = i + 1; j < anchors.length; j++) {
      const p1 = (anchors[i][1] as any).drawAt;
      const p2 = (anchors[j][1] as any).drawAt;
      closestPairDistance = Math.min(closestPairDistance, dist2(p1, p2));
    }
  }

  const triggerAreaLimit = Math.max(Math.sqrt(closestPairDistance), 10);
  const triggerAreaRadius = Math.min(Math.max(HandlePadding, pad) * 5, triggerAreaLimit / 2);

  return (
    <>
      {anchors.map(([side, anchor]) => (
        <Handle
          key={side}
          pos={(anchor as any).drawAt}
          angle={anchor.rotation}
          distanceToMouse={dist2(mousePos, (anchor as any).drawAt)}
          triggerAreaRadius={triggerAreaRadius}
          scale={scale}
          onClick={() => onCreateConnector("click", node, side, anchor)}
          onDragStart={() => onCreateConnector("drag", node, side, anchor)}
          onHoverStart={() => onAnchorHoverStart(node, side, anchor)}
          onHoverEnd={() => onAnchorHoverEnd()}
        />
      ))}
    </>
  );
}

interface HandleProps {
  pos: Point;
  angle: number;
  scale: number;
  distanceToMouse: number;
  triggerAreaRadius: number;
  onClick: (e: KonvaEventObject<MouseEvent>) => void;
  onDragStart: (e: KonvaEventObject<MouseEvent>) => void;
  onHoverStart: (e: KonvaEventObject<MouseEvent>) => void;
  onHoverEnd: (e: KonvaEventObject<MouseEvent>) => void;
}

function Handle({
  pos,
  angle,
  scale,
  distanceToMouse,
  triggerAreaRadius,
  onClick,
  onDragStart,
  onHoverStart,
  onHoverEnd,
}: HandleProps) {
  const activeRef = useRef<any>(null);
  let x = pos.x,
    y = pos.y;

  let r = HandleActiveRadius + 1; // we add 1 because of the stroke
  r /= scale;
  let pointerOnButton = distanceToMouse < r * r;

  let highlight = distanceToMouse < triggerAreaRadius * triggerAreaRadius;

  useEffect(() => {
    activeRef.current?.to({
      scaleX: highlight ? 1 : 0,
      scaleY: highlight ? 1 : 0,
      duration: AnimationDuration,
    });
  }, [highlight]);

  let arrowColor = pointerOnButton ? "white" : HandleActiveColor;
  let circleColor = pointerOnButton ? HandleActiveColor : "white";
  return (
    <Group x={x} y={y} scaleX={1 / scale} scaleY={1 / scale}>
      <Circle fill={HandleInactiveColor} radius={HandleInactiveRadius} listening={false} />

      <Group ref={activeRef} scaleX={0} scaleY={0}>
        <Circle
          name="shape-socket anchor"
          fill={circleColor}
          stroke={HandleActiveColor}
          radius={HandleActiveRadius}
          strokeWidth={2}
          onMouseOver={onHoverStart}
          onMouseOut={onHoverEnd}
          onMouseDown={(e) => {
            if (e.evt.buttons == 1) {
              e.target.attrs.click = true;
            }
          }}
          onMouseLeave={(e) => {
            if (e.evt.buttons == 1 && e.target.attrs.click) {
              onDragStart(e);
            }
          }}
          onMouseUp={(e) => {
            if (e.target.attrs.click) {
              onClick(e);
            }
          }}
        />
        <Group rotation={angle} listening={false}>
          <Line points={[-6, 0, 5, 0]} strokeWidth={2} stroke={arrowColor} fill={arrowColor} />
          <CanvasArrow
            pointerLength={5}
            pointerWidth={4}
            x={5}
            y={0}
            strokeWidth={2}
            stroke={arrowColor}
            fill={arrowColor}
          />
        </Group>
      </Group>
    </Group>
  );
}
