import BoundingBox from "frontend/geometry/bounding-box";
import Konva from "konva";
import consts from "shared/consts";

// We define our data objects match Konva's native objects
export interface Point {
  x: number;
  y: number;
}

// Define 2 types which are nominally different: containing same fields but not compatible
// with one another
// This is used to tell which coordinate system the point is in.
export enum ViewportCoordinates {
  _ = "",
}
export enum CanvasCoordinates {
  _ = "",
}
export enum ElementCoordinates {
  _ = "",
}

export type PointInViewport = ViewportCoordinates & Point;
export type PointInCanvas = CanvasCoordinates & Point;

// IRect defines the top-left point and width + height
export interface IRect {
  x: number;
  y: number;
  width: number;
  height: number;
}

let _tr = new Konva.Transform();

export function inverseTransform(tr: Konva.Transform, p: Point, out?: Point) {
  tr.copyInto(_tr);
  const result = _tr.invert().point(p);
  if (out != undefined) {
    out.x = result.x;
    out.y = result.y;
    return out;
  }
  return result;
}

interface IPointOrRect {
  x: number;
  y: number;
  width?: number;
  height?: number;
  rotation?: number;
}

interface PositionAndScale {
  x: number;
  y: number;
  scale: number;
}

function konvaTransformToPositionAndScale(tr: Konva.Transform): PositionAndScale {
  let { x, y } = tr.getTranslation();
  let scale = tr.getMatrix()[0];
  return { x, y, scale };
}

export function viewportToStage(tr: PositionAndScale | Konva.Transform, rect?: IPointOrRect) {
  if (rect == undefined) {
    return (r: IPointOrRect) => viewportToStage(tr, r);
  }

  let pos: PositionAndScale;
  pos = tr instanceof Konva.Transform ? konvaTransformToPositionAndScale(tr) : tr;

  let result: any = {
    x: (rect.x - pos.x) / pos.scale,
    y: (rect.y - pos.y) / pos.scale,
    width: (rect?.width ?? 0) / pos.scale,
    height: (rect?.height ?? 0) / pos.scale,
  };
  if ("rotation" in rect) {
    result.rotation = rect.rotation;
  }
  return result;
}

export function stageToViewport(tr: PositionAndScale | Konva.Transform, rect?: IPointOrRect) {
  if (rect == undefined) {
    return (r: IPointOrRect) => stageToViewport(pos, r);
  }
  let pos: PositionAndScale;
  pos = tr instanceof Konva.Transform ? konvaTransformToPositionAndScale(tr) : tr;

  let result: any = {
    x: rect.x * pos.scale + pos.x,
    y: rect.y * pos.scale + pos.y,
    width: (rect?.width ?? 0) * pos.scale,
    height: (rect?.height ?? 0) * pos.scale,
  };
  if ("rotation" in rect) {
    result.rotation = rect.rotation;
  }
  return result;
}

export type HLine = "top" | "bottom" | "middle";
export type VLine = "left" | "right" | "center";
export type RectLines = { [key in HLine | VLine]: number };

export enum Side {
  Left,
  Right,
  Top,
  Bottom,
  NumSides,
}

export const SideToString: { [key in Side]: string } = {
  [Side.Left]: "left",
  [Side.Right]: "right",
  [Side.Top]: "top",
  [Side.Bottom]: "buttom", // typo is on purpose for backwards compatability
  [Side.NumSides]: "overflow!!!",
};

export function getOppositeSide(side: Side | string) {
  switch (side.toString()) {
    case "left":
      return SideToString[Side.Right];
    case "right":
      return SideToString[Side.Left];
    case "top":
      return SideToString[Side.Bottom];
    case "buttom":
      return SideToString[Side.Top];
    default:
      return SideToString[Side.NumSides];
  }
}

// ------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------

export function clamp(x: number, low: number, high: number): number {
  if (x < low) return low;
  if (x > high) return high;
  return x;
}

// smooth maximum functions - look at wikipedia for explanation
export function smoothMax(a: number, b: number, k = 1) {
  const res = Math.exp(k * a) + Math.exp(k * b);
  return Math.log(res) / k;
}
export function smoothMaxUnit(a: number, b: number, epsilon = 0.001) {
  return a + b + Math.sqrt(Math.pow(a - b, 2) + epsilon) / 2;
}

// export function smoothMinPoly(a: number, b: number, k: number) {
//   const h = Math.max(k - Math.abs(a - b), 0.0) / k;
//   return Math.min(a, b) - h * h * k * (1.0 / 4.0);
// }

// export function smoothMinCubic(a: number, b: number, k: number) {
//   const h = Math.max(k - Math.abs(a - b), 0.0) / k;
//   return Math.min(a, b) - h * h * h * k * (1.0 / 6.0);
// }

export function lerp(a: number, b: number, t: number) {
  return a + (b - a) * t;
}

export function lerp2(x1: number, y1: number, x2: number, y2: number, t: number): [number, number] {
  return [lerp(x1, x2, t), lerp(y1, y2, t)];
}

export function dist2(p1: Point, p2: Point): number {
  return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
}

export function midpoint(p1: Point, p2: Point) {
  return { x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2 };
}

export function moveTowards(num: number, target: number, maxDelta: number) {
  if (num > target) return Math.max(target, num - maxDelta);
  else if (num < target) return Math.min(target, num + maxDelta);
  else return num;
}

export function closestPointOnSegment(p: Point, v: Point, w: Point): [number, Point] {
  var l2 = dist2(v, w);
  if (l2 == 0) return [0, v];
  let t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
  t = clamp(t, 0, 1);
  return [
    t,
    {
      x: v.x + t * (w.x - v.x), // point.lerp(v,w,t)
      y: v.y + t * (w.y - v.y),
    },
  ];
}

export function distToSegmentSquared(p: Point, v: Point, w: Point) {
  const [t, X] = closestPointOnSegment(p, v, w);
  return dist2(p, X);
}

// The distance between point 'p' and the segment define between 'v' and 'w'
// Segment is finite
export function distanceToSegment(p: Point, v: Point, w: Point) {
  return Math.sqrt(distToSegmentSquared(p, v, w));
}

// Check if a rectangle intersects a segment
// Adaptation of Liang Barsky algorithm for checking intersection of line with rectangle.
// https://en.wikipedia.org/wiki/Liang%E2%80%93Barsky_algorithm
// Nice pseudo code in: https://gist.github.com/ChickenProp/3194723 (it has a bug, this code is correct)
export function rectIntersectSegment(rect: IRect, lineA: Point, lineB: Point) {
  return rectIntersectSegment2(rect, lineA.x, lineA.y, lineB.x, lineB.y);
}

export function rectIntersectSegment2(rect: IRect, x0: number, y0: number, x1: number, y1: number) {
  const min = {
    x: Math.min(rect.x, rect.x + rect.width),
    y: Math.min(rect.y, rect.y + rect.height),
  };
  const max = {
    x: Math.max(rect.x, rect.x + rect.width),
    y: Math.max(rect.y, rect.y + rect.height),
  };
  const dx = x1 - x0;
  const dy = y1 - y0;
  var p = [-dx, dx, -dy, dy];
  var q = [x0 - min.x, max.x - x0, y0 - min.y, max.y - y0];
  var u1 = 0;
  var u2 = 1;

  for (let i = 0; i < 4; i += 1) {
    if (p[i] == 0) {
      if (q[i] < 0) return false;
    } else {
      var t = q[i] / p[i];
      if (p[i] < 0 && u1 < t) u1 = t;
      else if (p[i] > 0 && u2 > t) u2 = t;
    }
  }

  if (u1 > u2) return false;
  return true;
}

// Algorithm taken from "Graphic gems 3"
// Can be found at https://www.realtimerendering.com/resources/GraphicsGems/gemsiii/insectc.c
export function doSegmentsIntersect(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number
): boolean {
  let Ax, Bx, Cx, Ay, By, Cy, d, e, f;
  let x1lo, x1hi, y1lo, y1hi;

  Ax = x2 - x1;
  Bx = x3 - x4;

  if (Ax < 0) {
    x1lo = x2;
    x1hi = x1;
  } else {
    x1hi = x2;
    x1lo = x1;
  }
  if (Bx > 0) {
    if (x1hi < x4 || x3 < x1lo) return false;
  } else {
    if (x1hi < x3 || x4 < x1lo) return false;
  }

  Ay = y2 - y1;
  By = y3 - y4;

  if (Ay < 0) {
    y1lo = y2;
    y1hi = y1;
  } else {
    y1hi = y2;
    y1lo = y1;
  }
  if (By > 0) {
    if (y1hi < y4 || y3 < y1lo) return false;
  } else {
    if (y1hi < y3 || y4 < y1lo) return false;
  }

  Cx = x1 - x3;
  Cy = y1 - y3;

  f = Ay * Bx - Ax * By;

  if (f == 0) return false; // parallel lines

  d = By * Cx - Bx * Cy;
  if (f > 0) {
    if (d < 0 || d > f) return false;
  } else {
    if (d > 0 || d < f) return false;
  }

  e = Ax * Cy - Ay * Cx;
  if (f > 0) {
    if (e < 0 || e > f) return false;
  } else {
    if (e > 0 || e < f) return false;
  }

  return true;
}
// This function takes 2 positions and returns the bounding box
// The positions are assumed to be opposite corners of the box,
// but if 'isCentered' is true, the positions are assumed to be center and corner.
// when 'isEquiSided' is true, the bounding box will have equal width and height
// Returns: [top-left, bottom-right]
export function computeBoundingBox(
  pos1: Point,
  pos2: Point,
  isCentered: boolean,
  isEquiSided: boolean
): [Point, Point] {
  let w = Math.abs(pos1.x - pos2.x);
  let h = Math.abs(pos1.y - pos2.y);
  if (isEquiSided) {
    const side = Math.max(w, h);
    w = side;
    h = side;
    const dx_sign = Math.sign(pos2.x - pos1.x);
    const dy_sign = Math.sign(pos2.y - pos1.y);
    pos2 = { x: pos1.x + dx_sign * w, y: pos1.y + dy_sign * h };
  }
  if (isCentered) {
    // centered scaling - shape is created from the center outwards
    //todo: there are duplicate calculations like these in GhostCanvasElement and elsewhere: refactor!!
    const centerX = pos1.x;
    const centerY = pos1.y;
    pos1 = { x: centerX - w, y: centerY - h };
    pos2 = { x: centerX + w, y: centerY + h };
  }
  return [pos1, pos2];
}

export type ConnectionPointsObjects = {
  left: PointInCanvas;
  right: PointInCanvas;
  top: PointInCanvas;
  buttom: PointInCanvas;
};

export function getConnectionPointsObject(type: string, rect: IRect): ConnectionPointsObjects {
  let left: PointInCanvas, right: PointInCanvas, top: PointInCanvas, buttom: PointInCanvas;
  if (type == consts.SHAPES.TRIANGLE) {
    const top_point = { x: rect.x + rect.width / 2, y: rect.y };
    const left_point = { x: rect.x, y: rect.y + rect.height };
    const right_point = { x: rect.x + rect.width, y: rect.y + rect.height };
    left = midpoint(top_point, left_point) as PointInCanvas;
    right = midpoint(top_point, right_point) as PointInCanvas;
    top = top_point as PointInCanvas;
    buttom = midpoint(right_point, left_point) as PointInCanvas;
  } else {
    left = { x: rect.x, y: rect.y + rect.height / 2 } as PointInCanvas;
    right = { x: rect.x + rect.width, y: rect.y + rect.height / 2 } as PointInCanvas;
    top = { x: rect.x + rect.width / 2, y: rect.y } as PointInCanvas;
    buttom = { x: rect.x + rect.width / 2, y: rect.y + rect.height } as PointInCanvas;
  }
  return { left, right, top, buttom };
}

export function findClosestPoint(origin: Point, points: Point[]): [number, number] {
  let min = Number.MAX_SAFE_INTEGER;
  let minIndex = 0;
  for (let i = 0; i < points.length; i++) {
    const distanceSqr = dist2(origin, points[i]);
    if (distanceSqr < min) {
      min = distanceSqr;
      minIndex = i;
    }
  }
  return [Math.sqrt(min), minIndex];
}

export function isPointInRect(p: Point, r: IRect): boolean {
  const tolerance = 1e-3;
  return (
    p.x >= r.x - tolerance &&
    p.x <= r.x + r.width + tolerance &&
    p.y >= r.y - tolerance &&
    p.y <= r.y + r.height + tolerance
  );
}

export function arePointsEqual(p1: Point, p2: Point) {
  return Math.abs(p1.x - p2.x) < 1e-3 && Math.abs(p2.x - p2.y) < 1e-3;
}

export function unionOfRects(rects: Iterable<IRect>): IRect {
  let minX = Number.MAX_SAFE_INTEGER,
    minY = Number.MAX_SAFE_INTEGER,
    maxX = Number.MIN_SAFE_INTEGER,
    maxY = Number.MIN_SAFE_INTEGER;
  for (const rect of rects) {
    minX = Math.min(minX, rect.x);
    minY = Math.min(minY, rect.y);
    maxX = Math.max(maxX, rect.x + rect.width);
    maxY = Math.max(maxY, rect.y + rect.height);
  }
  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
}

export function rectLines(rect: IRect, xOffset = 0, yOffset = 0): RectLines {
  return {
    top: rect.y + yOffset,
    bottom: rect.y + yOffset + rect.height,
    middle: rect.y + yOffset + rect.height / 2,
    left: rect.x + xOffset,
    right: rect.x + xOffset + rect.width,
    center: rect.x + xOffset + rect.width / 2,
  };
}

export function unionSegments(v: [number, number], w?: [number, number]): [number, number] {
  if (!w) return v;
  return [Math.min(v[0], w[0]), Math.max(v[1], w[1])];
}

export function getBoundingBox(nodes: Iterable<Konva.Node>, options?: { relativeTo?: Konva.Container }): BoundingBox {
  let box = new BoundingBox();
  for (const node of nodes) {
    const rect = node.getClientRect(options);
    box.expandRect(rect);
  }
  return box;
}

export function numberWithCommas(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function rectIntersectLines(
  targetRect: IRect,
  points: number[],
  { x = 0, y = 0, scaleX = 1, scaleY = 1 }: { x: number; y: number; scaleX?: number; scaleY?: number }
) {
  let localRect: any = {};
  localRect.x = (targetRect.x - x) / scaleX;
  localRect.y = (targetRect.y - y) / scaleY;
  localRect.width = targetRect.width / scaleX;
  localRect.height = targetRect.height / scaleY;
  // now localRect is in the coordinate system of the points

  for (let j = 0; j <= points.length - 4; j += 2) {
    const x1 = points[j + 0];
    const y1 = points[j + 1];
    const x2 = points[j + 2];
    const y2 = points[j + 3];
    const check = rectIntersectSegment2(localRect, x1, y1, x2, y2);
    if (check) return true;
  }
  return false;
}

export function isPointCloseToLines(point: Point, linePoints: number[], threshold: number) {
  const thresholdSqr = threshold * threshold;

  for (let j = 0; j <= linePoints.length - 4; j += 2) {
    const x1 = linePoints[j + 0];
    const y1 = linePoints[j + 1];
    const x2 = linePoints[j + 2];
    const y2 = linePoints[j + 3];
    const d = distToSegmentSquared(point, { x: x1, y: y1 }, { x: x2, y: y2 });
    if (d < thresholdSqr) {
      return true;
    }
  }
  return false;
}

export function padRect(rect: IRect, padding: number) {
  if (padding < 0 && (-2 * padding > rect.width || -2 * padding > rect.height)) {
    padding = -Math.min(rect.width, rect.height); // never return negative rect size
  }
  return {
    x: rect.x - padding,
    y: rect.y - padding,
    width: rect.width + padding * 2,
    height: rect.height + padding * 2,
  };
}

export const doesBoxCoversAnother = (outerBox: IRect) => (innerBox: IRect) => {
  return (
    outerBox.x <= innerBox.x &&
    outerBox.y <= innerBox.y &&
    outerBox.x + outerBox.width >= innerBox.x + innerBox.width &&
    outerBox.y + outerBox.height >= innerBox.y + innerBox.height
  );
};
