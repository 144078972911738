import React from "react";
import style from "./colorPicker.module.css";
import { DropdownButton } from "../toolbar-buttons";
import { ColorOpacityPicker, SolidColor, OutlineColor } from "frontend/ui-components/color-opacity-picker";

export function ColorPicker({
  value,
  onChange,
  multi,
  colorPalette,
  customColors,
  isText,
  withTransparency = false,
  tooltip,
  onlyOutline = false,
  allowCustomColor = false,
}: {
  value?: string;
  onChange?: (color: any, addUndo: boolean) => void;
  multi?: boolean;
  colorPalette?: any;
  customColors?: any;
  isText?: boolean;
  withTransparency?: boolean;
  tooltip?: string;
  onlyOutline?: boolean;
  allowCustomColor?: boolean;
}) {
  function renderColorPalette() {
    return (
      <ColorOpacityPicker
        value={value}
        onChange={onChange}
        colorPalette={colorPalette}
        customColors={customColors}
        withTransparency={withTransparency}
        allowCustomColor={allowCustomColor}
      />
    );
  }

  const iconColor = multi
    ? "conic-gradient(#ff4800, #dfd902, #20dc68, #0092f4, #da54d8, #ff4800)"
    : value || "transparent";

  return (
    <DropdownButton
      tooltip={tooltip ?? "Change color"}
      renderPopup={() => renderColorPalette()}
      data-testid={onlyOutline ? "strokecolorpicker" : "FloatingColorPicker"}
      className={style.colorPopup}
    >
      {onlyOutline ? <OutlineColor color={iconColor} /> : <SolidColor color={iconColor} />}
    </DropdownButton>
  );
}
